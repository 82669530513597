import service from "../service.js";

//查询角色信息
export const searchRoleData = params => {
    return service({
      method: 'POST',
      url: '/role/searchRoles',
      params
    })
}

//获取所有角色信息
export const queryRoleAll = () => {
    return service({
      method: 'GET',
      url: '/role/getRoleAll',
    })
}

export const getRoles = () => {
    return service({
      method: 'GET',
      url: '/role/getRoles',
    })
}

//获取角色级别
export const getGrades = () => {
  return service({
    method: 'GET',
    url: '/param/getRoleGrades',
  })
}

//删除角色信息
export const delRole = roleId => {
  return service({
    method: 'POST',
    url: '/role/deleteRole',
    params: {
      roleId
    }
  })
}

export const addRole = (data) => {
  return service({
    method: 'POST',
    url: '/role/addRole',
    data
  })
}

export const updateRole = (data) => {
  return service({
    method: 'POST',
    url: '/role/editRole',
    data
  })
}

export const queryRole = (roleId) => {
  return service({
    method: 'POST',
    url: '/role/queryRole',
    params: {
      roleId
    }
  })
}
